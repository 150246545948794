const policy = '/task-activity-monitoring/document-upload/'
export const policyRequestListApi = policy + 'list'
export const documentUpdateApi = policy + 'update'
export const policyRequestToggleApi = policy + 'toggle-status'

const assignTaskCoordinator = '/research-plan-preparation/assign-task-coordinator/'
export const assignTaskCoordinatorList = assignTaskCoordinator + 'list'
export const assignTaskCoordinatorStore = assignTaskCoordinator + 'store'
export const assignTaskCoordinatorUpdate = assignTaskCoordinator + 'update'
export const assignTaskCoordinatorTogle = assignTaskCoordinator + 'toggle-status'
export const assignTaskCoordinatorShow = assignTaskCoordinator + 'show'

const rcvPayment = '/research-execution-management/activity-audit/rcv-payment/'
export const rcvPaymentListApi = rcvPayment + 'list'
export const rcvPaymentStoreApi = rcvPayment + 'store'
export const contractSignApi = rcvPayment + 'contract-sign-info'
export const rcvPaymentDetails = rcvPayment + 'rcv-payment-details'
export const contractSignedList = rcvPayment + 'contract-signed-list'

// Research Document Api...
const researchDocument = '/research-execution-management/research-document/'
export const researchDocumentList = researchDocument + 'list'
export const researchDocumentUploadDocument = researchDocument + 'upload-document'
export const researchDocumentForward = researchDocument + 'forward'
export const researchDocumentForwardedList = researchDocument + 'forwarded-list'
export const researchDocumentApprove = researchDocument + 'approve'
export const researchDocumentReject = researchDocument + 'reject'
export const researchDocumentReview = researchDocument + 'review'
export const researchDocumentApprovedList = researchDocument + 'approved-list'
export const researchDocumentSubmitDocument = researchDocument + 'submit-document'
export const researchDocumentAwardDocument = researchDocument + 'award-document'
export const researchDocumentNonAwardDocument = researchDocument + 'non-award-document'
export const researchDocumentCancelDocument = researchDocument + 'cancel-document'

const expenditureInfo = '/research-execution-management/activity-audit/expenditure-info/'
export const expenditureInfoListApi = expenditureInfo + 'list'
export const expenditureInfoStoreApi = expenditureInfo + 'store'
export const contractSigningApi = expenditureInfo + 'contract-sign-info'
export const expenditureInfoDetails = expenditureInfo + 'expenditure-details'
export const contractSignedLists = expenditureInfo + 'contract-signed-list'
export const expenditureCloseApi = expenditureInfo + 'expenditure-close'

const consultantPayment = '/research-execution-management/activity-audit/consultant-payment/'
export const consultantPaymentListApi = consultantPayment + 'list'
export const consultantPaymentStoreApi = consultantPayment + 'store'
export const contractSignedApi = consultantPayment + 'contract-sign-info'
export const consultantPaymentDetail = consultantPayment + 'detail'
export const contractSignLists = consultantPayment + 'contract-signed-list'
export const consultantInfoApi = consultantPayment + 'consultant-info'
export const consultantListApi = consultantPayment + 'consultant-list'
